import * as React from "react";
import Layout from '../components/layout';
import Container from '../components/container';
import {StaticImage} from "gatsby-plugin-image";

class ChangeShapersPage extends React.Component {
    constructor(props) {
        super(props);

        const faces = [
            {
                id: 'borys-sosnicki',
                img: (<StaticImage src="../images/changeshapers/BorysSosnicki.jpg" alt="Borys Sośnicki" />),
                name: 'Borys Sośnicki',
                desc: (<>
                    <p>Pochodzę z Sieradza - małego miasta, które jednak może pochwalić się bardzo bogatą historią. Od trzech lat mieszkam w Warszawie, gdzie studiuję w Szkole Głównej Handlowej, ciesząc się ogromną pasją do organizacji studenckich.</p>
                    <p>Jestem zaangażowanym działaczem, a zarazem przewodniczącym Erasmus Student Network SGH, angażuję się także aktywnie w ESN na poziomie ogólnopolskim i powoli przymierzam się do poziomu międzynarodowego.</p>
                    <p>W wolnych chwilach chodzę na profesjonalne degustacje alkoholi, jestem także w posiadaniu największej kolekcji pamiątek po nieistniejących już sieradzkich Zakładach Przemysłu Spirytusowego "Polmos". W momentach relaksu najczęściej czytam, oglądam animacje i filmy Marvela lub grywam w gry komputerowe</p>
                    <p>Nie mogę doczekać się prowadzenia prelekcji w szkołach średnich. Dostaję wręcz wypieków na twarzy, gdy myślę o tym, że będę mógł zarazić kolejny rocznik pasją do aktywności społecznej i po prostu zmieniać świat na lepsze.</p>
                </>)
            },
            {
                id: 'wawrzyniec-sokolowski',
                img: (<StaticImage src="../images/changeshapers/WawrzyniecSokołowski.jpg" alt="Wawrzyniec Sokołowski" />),
                name: 'Wawrzyniec Sokołowski',
                desc: (<>
                    <p>Rodem z małego miasta Milicz, położonego około 60 km od Wrocławia. W przeszłości próbowałem zostać lekarzem na wrocławskim Umedzie, z czasem jednak odkryłem kognitywistykę, którą studiuję obecnie na Uniwersytecie Jagiellońskim.</p>
                    <p>Jak można domyślić się po nazwie kierunku, fascynują mnie ludzie. To zainteresowanie rozwijam również w AEGEE-Kraków, jednym z polskich oddziałów międzynarodowej organizacji studenckiej, w ramach której udzielam się w grupach oraz pomagam przy organizacji projektów.</p>
                    <p>Wiem, jak przerażający i paraliżujący może być ogrom możliwości współczesności. Dlatego też chcę podzielić się z młodymi osobami innym spojrzeniem na dzisiejszy świat, a także iskierką fascynacji i zapału do życia według własnych schematów. Wszystko dzięki odkrywaniu i wykorzystaniu wspomnianych możliwości.</p>
                </>)
            },
            {
                id: 'martyna-zapalska',
                img: (<StaticImage src="../images/changeshapers/MartynaZapalska.jpg" alt="Martyna Zapalska" />),
                name: 'Martyna Zapalska',
                desc: (<>
                    <p>Pochodzę spod Krakowa, a obecnie studiuję Zarządzanie kulturą i mediami na Uniwersytecie Jagiellońskim.</p>
                    <p>Moje życie zajmują nie tylko zajęcia i egzaminy. Aktywnie działam w organizacji AEGEE-Kraków, której jestem dumnym prezydentem.</p>
                    <p>Interesuję się językami obcymi, fotografią i od czasu do czasu zdarza mi się żeglować.</p>
                    <p>Wiem, jak straszne bywają stawiane przed nami życiowe wybory, a nawet jak straszniejsza może być zmiana sama w sobie. Mimo to moim zdaniem nic nie dzieje się przypadkiem, dlatego uważam, że z każdego doświadczenia i działania można coś wynieść i wykorzystać dla swojego rozwoju. I w taki sposób podchodzę też do #ChangeShapers ;)</p>
                </>)
            },
            {
                id: 'natalia-szczucka',
                img: (<StaticImage src="../images/changeshapers/NataliaSzczucka.jpg" alt="Natalia Szczucka" />),
                name: 'Natalia Szczucka',
                desc: (<>
                    <p>Rodem z Krakowa, duszą i ciałem w Warszawie. Choć urodziłam się i wychowałam w dawnej stolicy, aktualnie żyję i mieszkam w stolicy obecnej. Studiuję Financial Management na Akademii Leona Koźmińskiego.</p>
                    <p>Na co dzień pracuję także w firmie konsultingowej ConQuest Consulting, w środowisku uczelnianym jestem z kolei założycielką SKN Women in Organisations, inicjatywy funkcjonującej w ramach mojej uczelni.</p>
                    <p>Skupiona na ciągłym rozwoju, zarówno technologicznym, jak i medycznym. Interesuję się rozwiązaniami medtech oraz digital health. Gdy potrzebuję przerwy od codziennych obowiązków, oddaję się gotowaniu, literaturze oraz sportom wodnym.</p>
                </>)
            },
            {
                id: 'rafal-gorniak',
                img: (<StaticImage src="../images/changeshapers/RafalGoorniak.jpg" alt="Rafał Górniak" />),
                name: 'Rafał Górniak',
                desc: (<>
                    <p>Pochodzę z Łodzi i też tam mieszkam. Aktualnie studiuję Information Technology na Politechnice Łódzkiej. W wolnym czasie programuję, co okazuje się bardzo przydatne w trakcie zajęć.</p>
                    <p>Staram się wyciągnąć możliwie najwięcej z okresu akademickiego. Aktywnie działam w organizacji ESN-EYE oraz w Samorządzie Politechniki. Ten właśnie zapał pragnę jedynie pogłębiać w trakcie programu #ChangeShapers.</p>
                    <p>Interesuje się sportem praktycznie w każdej formie, szczególnie tenisem i pływaniem. Sam trenuję bieganie. Dodatkowo, tu ciekawostka, bardzo lubię opiekować się roślinkami w moim ogrodzie ;)</p>
                </>)
            },
            {
                id: 'krzesimir-koniecko',
                img: (<StaticImage src="../images/changeshapers/KrzesimirKoniecko.jpg" alt="Krzesimir Koniecko" />),
                name: 'Krzesimir Koniecko',
                desc: (<>
                    <p>Dumne dziecko Gdyni, miasta morza i marzeń. Nie mogłem zatem opuścić Trójmiasta. Studiuję więc Inżynierię środowiska na Politechnice Gdańskiej.</p>
                    <p>Moja aktywność w życiu studenckim nie ogranicza się tylko do jednego miejsca i jednej organizacji. Swoją pasję do akademickiej społeczności wyrażam poprzez Koło Naukowe, Wydziałową Radę Studentów oraz najstarszy samorząd studencki w Polsce.</p>
                    <p>Kocham sporty każdej maści. Larpuje i RPG-uję (w skrócie: bardzo lubię gry komputerowe), uwielbiam też tańczyć. W życiu przyświeca mi idea, że niebo nie jest limitem, a jedyny limit to ten, który określamy sami.</p>
                </>)
            },
            {
                id: 'paulina-natkaniec',
                img: (<StaticImage src="../images/changeshapers/PaulinaNatkaniec.jpg" alt="Paulina Natkaniec" />),
                name: 'Paulina Natkaniec',
                desc: (<>
                    <p>Pochodzę z Piotrkowa Trybunalskiego, a od czterech lat mieszkam i studiuję w Krakowie. Kilka miesięcy temu obroniłam tytuł inżyniera na Akademii Górniczo-Hutniczej na kierunku Zarządzanie i Inżynieria Produkcji. Obecnie w trakcie magisterki na tym samym kierunku.</p>
                    <p>Wywodzę się z dwóch organizacji studenckich: Board of European Students of Technology oraz Uczelnianej Rady Samorządu Studentów AGH (Komisja ds. projektów).</p>
                    <p>Od lat pasjonuję się jeździectwem, uwielbiam też rolki oraz łyżwy. Od pewnego czasu wciągnął mnie także świat roślin i udało mi się zebrać już chyba całkiem ładną kolekcję :)</p>
                    <p>Pragnę pokazać, że każda organizacja studencka to nie tylko pozytywnie zakręceni i niesamowicie uśmiechnięci ludzie, ale też ogrom możliwości.</p>
                </>)
            },
            {
                id: 'aleksandra-milewska',
                img: (<StaticImage src="../images/changeshapers/AleksandraMilewska.jpg" alt="Aleksandra Milewska" />),
                name: 'Aleksandra Milewska',
                desc: (<>
                    <p>Szalona studentka ekonomii drugiego roku na Wydziale Nauk Ekonomicznych Uniwersytetu Warszawskiego. Pochodzę z małej mazowieckiej wioski, aktualnie mieszkam na warszawskiej Woli.</p>
                    <p>Jestem czynnym członkiem Samorządu Studentów mojego wydziału, gdzie należę do Komisji projektowej oraz Komisji ds. Biznesu i relacji zewnętrznych.</p>
                    <p>Określam się amatorką adrenaliny. Jakakolwiek aktywność fizyczna sprawia mi ogromną satysfakcję: siłownia, fitness, latem rower, zimą narty. I tak cały czas ;)</p>
                    <p>Jestem osobą towarzyską, pewną siebie, która nie boi się wyzwań. Nigdy się nie poddaję, bo wiem, że po trudniejszych momentach w życiu zawsze przyjdą te lepsze. Moje motto życiowe to: "Afirmacja jest kluczem do sukcesu".</p>
                </>)
            },
            {
                id: 'karolina-folta',
                img: (<StaticImage src="../images/changeshapers/KarolinaFolta.jpg" alt="Karolina Folta" />),
                name: 'Karolina Folta',
                desc: (<>
                    <p>Pochodzę z Brzozowa na Podkarpaciu. Życiowe losy przeniosły mnie jednak do Małopolski, gdzie aktualnie studiuję Inżynierię Mechatroniczną na Akademii Górniczo-Hutniczej w Krakowie.</p>
                    <p>W środowiskach akademickich obecna od kilku lat. Od ponad trzech udzielam się w organizacji studenckiej BEST AGH Kraków, gdzie zajmuję się głównie kontaktem z firmami zainteresowanymi współpracą w ramach naszych inicjatyw.</p>
                    <p>W wolnym czasie uwielbiam chodzić na koncerty rockowe i aktywnie spędzać czas. Oczywiście z przyjaciółmi :)</p>
                </>)
            },
            {
                id: 'maksymilian-czekaj',
                img: (<StaticImage src="../images/changeshapers/MaksymilianCzekaj.jpg" alt="Maksymilian Czekaj" />),
                name: 'Maksymilian Czekaj',
                desc: (<>
                    <p>Pochodzę z Krakowa, ale mieszkam w Warszawie. Tak, to możliwe ;)</p>
                    <p>Aktualnie studiuję Zarządzanie w ujęciu Socjologii Biznesu i Mediów na Akademii Leona Koźmińskiego. Przez rok studiowałem na krakowskim AWF, lecz z czasem zmieniły się moje priorytety, które znacząco podążyły w kierunku biznesu.</p>
                    <p>Aktualnie działam w samorządzie uczelni jako opiekun marketingu. Moją największą pasją jest sport, a w szczególności piłka nożna. Interesuję się także branżą fryzjerską, sam obcinam włosy oraz brody, będąc samą czynnością coraz bardziej zafascynowany.</p>
                    <p>Moim zdaniem należy zadać sobie jedno bardzo ważne pytanie: "Co chcesz w życiu robić?". A potem zacząć to robić.</p>
                </>)
            },
            {
                id: 'alina-paharzhelskaya',
                img: (<StaticImage src="../images/changeshapers/Alina_Paharzhelskaya.jpg" alt="Alina Paharzhelskaya" />),
                name: 'Alina Paharzhelskaya',
                desc: (<>
                    <p>Pochodzę z Białorusi, aktualnie studiuję Zarządzanie i socjologię w biznesie i mediach na Akademii Leona Koźmińskiego w Warszawie.</p>
                    <p>Jestem członkinią działu PR i Marketingu w Samorządzie Studenckim oraz członkinią koła naukowego "Women in organisations".</p>
                    <p>Interesuję się psychologią, projektowaniem graficznym i historią sztuki.</p>
                    <p>W trakcie programu przedstawię dwanaście powodów, dla których warto działać w organizacjach studenckich. Opowiem też o procesach wewnętrznych, z którymi na pewno zetkną się inni uczestnicy. Warto czekać ;)</p>
                </>)
            },
            {
                id: 'iga-swiatek',
                img: (<StaticImage src="../images/changeshapers/Iga_Swiatek.jpg" alt="Iga Świątek" />),
                name: 'Iga Świątek',
                desc: (<>
                    <p>Cześć, tu Iga z okolic Jasła. Obecnie studiuję Inżynierię i Zarządzanie Procesami Przemysłowymi na Akademii Górniczo-Hutniczej w Krakowie.</p>
                    <p>Moją specjalność akademicka to Controlling procesów produkcyjnych. Od około dwóch lat czynnie działam w Komisji ds. Projektów Uczelnianej Rady Samorządu Studentów AGH, a także w Studenckim Kole Naukowym "Zarządzanie" i ESN AGH Kraków.</p>
                    <p>Życie to jednak nie tylko same studia. Poza tym interesuję się szeroko pojętym digital marketingiem, muzyką oraz organizacją wydarzeń. Każdą z powyższych pasji podzielę się podczas programu #ChangeShapers ;)</p>
                </>)
            },
            {
                id: 'natalia-biernacka',
                img: (<StaticImage src="../images/changeshapers/NataliaBiernacka.jpg" alt="Natalia Biernacka" />),
                name: 'Natalia Biernacka',
                desc: (<>
                    <p>W głowie i sercu tylko jedno miasto. Pochodzę z Łodzi. Mieszkam w Łodzi. Studiuję w Łodzi, dokładnie Matematykę Stosowaną na Politechnice Łódzkiej.</p>
                    <p>Na drugim semestrze studiów postanowiłam zaangażować się w działalność Wydziałowej Rady Samorządu oraz aplikowałam do Stowarzyszenia Studentów BEST Łódź. Z powodzeniem ;)</p>
                    <p>Uwielbiam angażować się społecznie i podróżować, a także poznawać nowych ludzi. Przyszłym studentom chciałabym pokazać, że przyszłość zależy tylko od nas samych. Pragnę także udowodnić, że ograniczenia istnieją wyłącznie w naszych głowach.</p>
                </>)
            },
            {
                id: 'martyna-wawrzen',
                img: (<StaticImage src="../images/changeshapers/MartynaWawrzen.jpg" alt="Martyna Wawrzeń" />),
                name: 'Martyna Wawrzeń',
                desc: (<>
                    <p>Pochodzę ze Śląska, dokładniej z Żor. Tam się urodziłam i skończyłam szkołę podstawową, a aktualnie studiuję Inżynierię Zarządzania na Politechnice Łódzkiej.</p>
                    <p>Wyjechałam do dużego miasta, aby spróbować swoich sił w samodzielnym życiu. Tak się składa, że nie potrafię usiedzieć w miejscu, więc gdy tylko miałam okazję, zaczęłam działać w Stowarzyszeniu Studentów BEST przy Politechnice Łódzkiej. Nabrałam więc dużo doświadczenia dzięki wielu szkoleniom oraz pracy projektowej.</p>
                    <p>W wolnym czasie interesuje się fotografią, podróżami oraz świadomą modą.</p>
                    <p>Podczas spotkań, które chciałabym przeprowadzić, opowiem o swojej podróży życiowej oraz o tym, jak pewność siebie i otwartość na ludzi pomogły mi przy tak częstej zmianie otoczenia.</p>
                </>)
            },
            {
                id: 'hubert-swierad',
                img: (<StaticImage src="../images/changeshapers/HubertSwierad.jpg" alt="Hubert Świerad" />),
                name: 'Hubert Świerad',
                desc: (<>
                    <p>Całe życie spędziłem jak dotąd w rodzinnym mieście - Łodzi. Po zdanej maturze ścieżkę kariery skierowałem na uczelnię techniczną, czyli Politechnikę Łódzką.</p>
                    <p>W trakcie akademickiej przygody rozpocząłem przygodę kolejną, w ramach działającego na całą Europę Stowarzyszenia Studenckiego BEST PŁ. Wolny czas wypełniam żeglarstwem oraz nauką montażu filmów.</p>
                    <p>Zależałoby mi, aby moje spotkania z innymi osobami w trakcie programu #ChangeShapers były motywacją dla młodych ludzi nie tylko do nauki, ale przede wszystkim do podejmowania przeróżnych działań w trakcie studiów.</p>
                </>)
            },
            {
                id: 'pola-stefaniak',
                img: (<StaticImage src="../images/changeshapers/PolaStefaniak.jpg" alt="Pola Stefaniak" />),
                name: 'Pola Stefaniak',
                desc: (<>
                    <p>Pochodzę z Gdańska, lecz koleje akademickich losów przeniosły mnie do Warszawy. Studiuję Zarządzanie w języku angielskim na Akademii Leona Koźmińskiego.</p>
                    <p>Oczywiście działam także w organizacjach studenckich. Jestem Przewodniczącą Zarządu Samorządu Studenckiego Akademii Leona Koźmińskiego.</p>
                    <p>Na pytanie o to, czym się zajmuję, zawsze odpowiadam krótko i zwięźle: "Kocham motoryzację i pieski". I nic w tej materii się nie zmienia ;)</p>
                </>)
            },
            {
                id: 'karolina-olender',
                img: (<StaticImage src="../images/changeshapers/KarolinaOlender.jpg" alt="Karolina Olender" />),
                name: 'Karolina Olender',
                desc: (<>
                    <p>Cześć! Nazywam się Karolina i mieszkam w Warszawie, gdzie studiuję Zarządzanie na Uniwersytecie Warszawskim ;)</p>
                    <p>Obecnie działam w Studenckim Kole Naukowym Finansów Uniwersytetu Warszawskiego.</p>
                    <p>Moją wielką pasją są mapy, z którymi też pracuję na co dzień. Kocham również podróże - już niedługo planuję odbyć swój trzeci wyjazd w ramach programu Erasmus, bowiem bardzo lubię mieszkać w innych krajach, poznawać tamtejszą kulturę oraz społeczność.</p>
                    <p>Największą inspiracją dla mnie są po prostu ludzie. Zwłaszcza ci, którymi się otaczam.</p>
                </>)
            },
            {
                id: 'filip-bogdal',
                img: (<StaticImage src="../images/changeshapers/Filip_Bogdal.jpg" alt="Filip Bogdał" />),
                name: 'Filip Bogdał',
                desc: (<>
                    <p>Pochodzę z Krakowa i tam też mieszkam. Obecnie studiuję na Uniwersytecie Ekonomicznym, na kierunku Finanse i Rachunkowość pierwszego roku studiów uzupełniających magisterskich.</p>
                    <p>Od trzech lat należę do Koła Naukowego Rachunkowości, którego jestem też prezesem. Dodatkowo działam w ramach Zarządu Klubu Młodych Księgowych przy Krakowskim oddziale okręgowym Stowarzyszenia Księgowych w Polsce.</p>
                    <p>Moje zainteresowania to szeroko pojęta rachunkowość, świat finansów, a także futbol amerykański.</p>
                    <p>Dzięki programowi #ChangeShapers chciałbym przekazać młodym osobom, jak ważne i owocne może być działanie w organizacjach studenckich. Uważam, że jest to obecnie klucz do sukcesu w realizacji swoich planów oraz pełnego rozwoju w trakcie studiów.</p>
                </>)
            },
            {
                id: 'kamila-bielecka',
                img: (<StaticImage src="../images/changeshapers/Kamila_Bielecka.jpg" alt="Kamila Bielecka" />),
                name: 'Kamila Bielecka',
                desc: (<>
                    <p>Pochodzę z małej miejscowości w województwie lubuskim, lecz obecnie mieszkam w moim ukochanym Krakowie. Od dwóch lat studiuję Zarządzanie międzynarodowe na Uniwersytecie Jagiellońskim.</p>
                    <p>Należę do AEGEE - największej międzynarodowej, interdyscyplinarnej organizacji studenckiej w Europie.</p>
                    <p>Interesują mnie ludzie, lubię z nimi rozmawiać i poznawać ich historie oraz spojrzenie na świat. W wolnym czasie podróżuję, czytam książki i spaceruję.</p>
                    <p>Podczas swojej prezentacji chciałabym uświadomić młodym ludziom, jak wiele szans czeka na nich w życiu. Pragnę także przekazać wiedzę, że nie nie warto ograniczać się do tego, co robią lub mówią nasi znajomi.</p>
                </>)
            },
            {
                id: 'karolina-brydak',
                img: (<StaticImage src="../images/changeshapers/Karolina_Brydak.jpg" alt="Karolina Brydak" />),
                name: 'Karolina Brydak',
                desc: (<>
                    <p>Obecnie mieszkam w Rzeszowie i tam też studiuję na Kolegium Nauk Medycznych Uniwersytetu Rzeszowskiego.</p>
                    <p>Co mogę o sobie powiedzieć? Przede wszystkim osoba aktywna społecznie. Działam w kołach naukowych "Rech-Tech" i SKN w geriatrii i profilaktyce zdrowia.</p>
                    <p>W wolnej chwili chętnie podejmuję się wolontariatu w różnych obszarach. Moją największą pasją jest muzyka, którą rozwijam, ucząc się i tworząc koncerty podczas prowadzenia projektów społecznych.</p>
                    <p>Chciałabym pokazać młodszym kolegom korzyści z podejmowanych aktywności społecznych czy naukowych. Chcę też zachęcić ich do tego, aby szukali siebie na różnych płaszczyznach, nie ograniczali się i pozwolili sobie na realizację własnych planów i marzeń.</p>
                </>)
            },
            {
                id: 'maksymilian-okonski',
                img: (<StaticImage src="../images/changeshapers/Maks_Okonski.jpg" alt="Maksymilian Okoński" />),
                name: 'Maksymilian Okoński',
                desc: (<>
                    <p>Cześć, z tej strony Maksymilian ;)</p>
                    <p>Jestem z Warszawy, w której mieszkam całe życie. Obecnie studiuję zarządzanie w języku angielskim w Akademii Leona Koźmińskiego.</p>
                    <p>Czasy akademickie staram się wykorzystać możliwie najefektywniej. Od stycznia 2022 roku jestem członkiem Samorządu Studentów w Komisji ds. Mniejszości i Równości. Dodatkowo uczestniczę w międzynarodowym projekcie Collaborative Online International Learning ze studentami z Auckland oraz Amsterdamu.</p>
                    <p>Interesuję się sportem i podróżami, lubię także języki obce oraz aktywne spędzanie czasu. Także podczas towarzyskich spotkań ze znajomymi ;)</p>
                </>)
            },
            {
                id: 'justyna-stanula',
                img: (<StaticImage src="../images/changeshapers/Justyna_Stanula.jpg" alt="Justyna Stanula" />),
                name: 'Justyna Stanula',
                desc: (<>
                    <p>Dumna i aktywna akademicko studentka III roku farmacji na Uniwersytecie Jagiellońskim Collegium Medicum w Krakowie.</p>
                    <p>Już od pierwszego roku studiów działam w Polskim Towarzystwie Studentów Farmacji - organizacji, która pozwala poszerzać wiedzę o przyszłym zawodzie i możliwych ścieżkach kariery. W tym roku mam przyjemność pełnić funkcję Koordynatorki Ogólnopolskiej ds. kontaktów z IPSF (międzynarodowym towarzystwem studentów farmacji). Udzielam się też w Wydziałowej Radzie Samorządu Studentów Wydziału Farmaceutycznego UJCM.</p>
                    <p>Chciałabym pokazać młodszym koleżankom i kolegom, że niezależnie od obszaru swoich zainteresowań czy kierunku studiów można znaleźć fantastyczne miejsce, rozwijać się i poznawać swoje mocne strony.</p>
                </>)
            },
            {
                id: 'dominik-surowiec',
                img: (<StaticImage src="../images/changeshapers/DominikSurowiec.jpg" alt="Dominik Surowiec" />),
                name: 'Dominik Surowiec',
                desc: (<>
                    <p>Rodem? Z małej miejscowości koło Rzeszowa w województwie podkarpackim. Studenckim statusem? Na III roku Przedsiębiorczości i Finansów na Uniwersytecie Ekonomicznym w Katowicach. Myślami? Przy inauguracji programu #ChangeShapers ;)</p>
                    <p>Obecnie jestem przewodniczącym Studenckiego Koła Naukowego "International Challenge".</p>
                    <p>Interesuję się psychologią (szczególnie zagadnieniami wielorakości umysłu), Internal Family System, a także finansami, inwestowaniem i ekonomią.</p>
                    <p>Młodszym kolegom i koleżankom chciałbym opowiedzieć o własnych doświadczeniach i swojej historii. Historii o chłopaku, który wiedział ,co chce robić w życiu, idąc drogą realizacji tych marzeń i sytuacji, która wszystko zmieniła.</p>
                </>)
            },
            {
                id: 'agnieszka-kopacz',
                img: (<StaticImage src="../images/changeshapers/AgnieszkaKopacz.jpg" alt="Agnieszka Kopacz" />),
                name: 'Agnieszka Kopacz',
                desc: (<>
                    <p>Wychowałam się w miejscowości Rejowiec, niewielkim miasteczku na Lubelszczyźnie, skąd wyjechałam na studia do Katowic. Tam uczę się na Wyższej Szkole Technologii Informatycznych na kierunku Grafika ze specjalizacją UX Design - projektowanie użyteczności.</p>
                    <p>Studia to dla mnie nie tylko nauka, dlatego aktywnie angażuję się w koło naukowe Lorem Ipsum, zrzeszające studentów pragnących tworzyć sztukę z zakresu szeroko pojętego projektowania graficznego.</p>
                    <p>Interesuje się wieloma aspektami z dziedziny grafiki, druku tradycyjnego i artystycznego, a także fotografią, zarządzaniem zasobami ludzkimi. Moje hobby to również zbieranie minerałów, geologia i ekologia.</p>
                </>)
            },
            {
                id: 'małgorzata-tobis',
                img: (<StaticImage src="../images/changeshapers/MałgorzataTobis.jpg" alt="Małgorzata Tobis" />),
                name: 'Małgorzata Tobis',
                desc: (<>
                    <p>Studentka ostatniego roku przedsiębiorczości i zarządzania innowacjami na Uniwersytecie Łódzkim.</p>
                    <p>Łącząc świat biznesu ze studentami, od blisko dwóch lat działam w Studenckim Forum Business Centre Club, gdzie reaktywowałam Region Łódź. Chwilę później przejęłam funkcję koordynatorki projektu Przedsiębiorcza Kobieta a z czasem wiceprzewodniczącej ds. administracyjno-finansowych. Na swoim koncie mam zrealizowanych wiele projektów: od maratonu webinarów, przez warsztaty biznesowe, aż wsparcie firm w działaniach marketingowych.</p>
                    <p>Kto mnie zna, ten wie, że jestem rozwojowym freakiem. Zawsze podzielę się informacją o wartościowym szkoleniu czy zbliżającym się wydarzeniu. Podczas spotkań z uczniami szkół średnich chcę podzielić się energią do rozwoju i działania.</p>
                </>)
            },
            {
                id: 'alisa-kushnyrenko',
                img: (<StaticImage src="../images/changeshapers/AlisaKushnyrenko.jpg" alt="Alisa Kushnyrenko" />),
                name: 'Alisa Kushnyrenko',
                desc: (<>
                    <p>Cześć wszystkim! Jestem Alisa, przyjechałam do Polski z Ukrainy na studia na Akademii Leona Koźmińskiego w Warszawie.</p>
                    <p>Studiuję Zarządzanie, lecz jestem także koordynatorem w organizacji studenckiej Kozminski International Society. Pomagam przyjeżdżającym z zagranicy studentom oswoić się z pobytem w Polsce i polubić ją tak, jak ja kiedyś polubiłam :)</p>
                    <p>W czasie wolnym od zajęć czas dużo czytam i uczę się języków obcych, realizując moje dwie największe pasje.</p>
                    <p>Dołączyłam do #ChangeShapers, ponieważ chcę nauczyć młodszych kolegów i koleżanek nie bać się przyszłości, tylko aktywnie kształtować ją według własnego gustu.</p>
                </>)
            },
            {
                id: 'aleksandra-banasiak',
                img: (<StaticImage src="../images/changeshapers/Aleksandra_Banasiak.jpg" alt="Aleksandra Banasiak" />),
                name: 'Aleksandra Banasiak',
                desc: (<>
                    <p>Pochodzę z Łodzi i tam też studiuję Information Technology w języku angielskim na Politechnice Łódzkiej.</p>
                    <p>Na drugim roku studiów aktywnie zaangażowałam się w życie stowarzyszenia studentów BEST, w którym udało mi się zdobyć doświadczenia z działu HR, PR oraz FR.</p>
                    <p>Uwielbiam podróżować, poznawać inne kultury oraz nowe osoby. To właśnie praca z ludźmi sprawia mi zdecydowanie największą przyjemność.</p>
                    <p>Młodym kolegom i koleżankom chciałabym przekazać możliwości i okazji rozwoju, z których po prostu należy korzystać. Moje podejście do życia? Zawsze warto próbować i wychodzić ze swojej strefy komfortu.</p>
                </>)
            },
            {
                id: 'dominika-ziolkowska',
                img: (<StaticImage src="../images/changeshapers/DominikaZiolkowska.jpg" alt="Dominika Ziółkowska" />),
                name: 'Dominika Ziółkowska',
                desc: (<>
                    <p>Nazywam się Dominika, mam 21 lat i pochodzę ze Świebodzina. Aktualnie studiuję filologię germańską ze specjalizacją nauczycielską na Uniwersytecie im. Adama Mickiewicza w Poznaniu.</p>
                    <p>Od początku bieżącego roku akademickiego należę do Uczelnianej Komisji Stypendialnej, a ponadto działam w programie Buddy.</p>
                    <p>Interesuję się teoriami nauczania języków obcych oraz technikami efektywnego uczenia się, a także marketingiem w sieci. Wolny czas poświęcam również na angażowanie się w różnego typu projekty i przedsięwzięcia, dzięki którym mogę zdobyć cenne doświadczenie.</p>
                    <p>Chcę pokazać innym, że studiowanie nie ogranicza się do tylko nauki na wybranym przez kierunku i może znaczyć o wiele, wiele więcej…</p>
                </>)
            },
            {
                id: 'barbara-wywial',
                img: (<StaticImage src="../images/changeshapers/BarbaraWywial.jpg" alt="Barbara Wywiał" />),
                name: 'Barbara Wywiał',
                desc: (<>
                    <p>Mam na imię Basia. Pochodzę z okolic Częstochowy, ale na okres studiów przeprowadziłam się do Warszawy i prawdopodobnie zostanę tu już na stałe. Studiuję finanse i rachunkowość na Akademii Leona Koźmińskiego.</p>
                    <p>Działam aktywnie w ramach akademickiej organizacji Women in Organisations. Bardzo ważny jest dla mnie rozwój i wspieranie kobiet.</p>
                    <p>Uwielbiam zwierzęta (mam dwa koty i psa), bardzo lubię poznawać nowych ludzi oraz próbować nowych rzeczy. Moje hobby to bieganie, pływanie, tenis oraz czytanie biografii.</p>
                    <p>Młodszym koleżankom i kolegom chciałabym przekazać brak strachu oraz wesprzeć w wychodzeniu poza strefę komfortu. W końcu człowiek w życiu najczęściej żałuje tych rzeczy, których nigdy nie zrobił.</p>
                </>)
            },
            {
                id: 'marcin-miszkiel',
                img: (<StaticImage src="../images/changeshapers/MarcinMiszkiel.jpg" alt="Marcin Miszkiel" />),
                name: 'Marcin Miszkiel',
                desc: (<>
                    <p>Jestem Marcin, pochodzę z Suwałk, a obecnie studiuję Informatykę i Ekonometrię na drugim roku Uniwersytetu Warszawskiego.</p>
                    <p>Obecnie działam w dwóch organizacjach: Samorządzie Studentów mojego wydziału oraz Radzie Mieszkańców w Domu Studenta. W międzyczasie pracuję na część etatu w kawiarni, w której to pogłębiam swoje zamiłowanie do kawy.</p>
                    <p>Dodatkowo interesuję się analizą danych, programowaniem i lekkoatletyką, którą trenowałem przez dziewięć lat.</p>
                    <p>Gdy myślę o tym, co chciałbym przekazać w trakcie #ChangeShapers, to zdecydowanie byłaby to odwaga przy podejmowaniu ryzyka, poszukiwanie alternatyw oraz otwartość na innych ludzi. Uważam że, wszystko, co nowe, czegoś nas uczy i przyczynia się do naszego rozwoju.</p>
                </>)
            },
            {
                id: 'iga-friedrich',
                img: (<StaticImage src="../images/changeshapers/igafriedrich.jpg" alt="Iga Friedrich" />),
                name: 'Iga Friedrich',
                desc: (<>
                    <p>Wiąże się ze mną dosyć ciekawa i niecodzienna historia. Choć pochodzę ze Szczecina, obecnie studiuję Zarządzanie na Uniwersytecie Ekonomicznym we… Wrocławiu.</p>
                    <p>W ramach mojej uczelni aktualnie działam także w tamtejszym Samorządzie Studentów.</p>
                    <p>W wolnym czasie czytam książki i oglądam filmy. Lubię również oglądać Formułę 1 oraz spotykać się ze znajomymi. Chwile z bliskimi cenię sobię tak samo mocno, jak te, które mogę spędzać sama.</p>
                    <p>Jaką życiową regułę wyznaję? Gdy świeca odpala inną świecę, nic nie traci. Dlatego też chciałabym podzielić się z innymi uczestnikami programu #ChangeShapers swoją wiedzą oraz doświadczeniem.</p>
                </>)
            },
            {
                id: 'dominika-szjegiec',
                img: (<StaticImage src="../images/changeshapers/DominikaSzejgiec.jpg" alt="Dominika Szjegiec" />),
                name: 'Dominika Szjegiec',
                desc: (<>
                    <p>Urodziłam się i wychowałam w Lublinie, lecz od czterech lat mieszkam w Krakowie i tam też studiuję na Uniwersytecie Jagiellońskim. W zeszłym roku skończyłam studia licencjackie na kierunku Latynoamerykanistyka, aktualnie jestem na I roku studiów magisterskich z Socjologii.</p>
                    <p>Od początku studiów działałam w kołach naukowych oraz organizacjach studenckich, jedną nawet współzakładałam. Od dwóch lat pełnię funkcję wiceprzewodniczącej Rady Kół Naukowych UJ.</p>
                    <p>Na co dzień zaś interesuję się podróżami oraz kulturą popularną Korei Południowej.</p>
                    <p>Chciałabym zachęcić przyszłych studentów i studentki do pokonania strachu i zrobienia pierwszego kroku w poznaniu działalności studenckiej. Najważniejsze dla mnie jest to, że studia mogą być czymś więcej niż tylko nauką, a wspólne działanie z innymi studentami może otworzyć nas na nowe życiowe doświadczenia.</p>
                </>)
            },
            {
                id: 'natalia-ozarek',
                img: (<StaticImage src="../images/changeshapers/NataliaOzarek.jpg" alt="Natalia Ożarek" />),
                name: 'Natalia Ożarek',
                desc: (<>
                    <p>Pochodzę z Piotrkowa Trybunalskiego, choć już od dwóch lat mieszkam w Krakowie, w którym jestem całkowicie zakochana, traktując miasto w stu procentach jak swoje. I tam też właśnie obecnie studiuję na drugim roku Informatyki i Systemów Inteligentnych na Akademii Górniczo-Hutniczej.</p>
                    <p>W ramach oderwania od technicznej strony studiów wstąpiłam do Uczelnianej Rady Samorządu Studentów do Komisji ds. Projektów, co pozwoliło mi całkowicie zmienić podejście do przyszłej drogi zawodowej. Otwierając przy tym kilka nowych perspektyw.</p>
                    <p>Prywatnie pasjonatka turystyki górskiej i tańców latynoamerykańskich, która podczas wystąpienia chciałaby opowiedzieć o tym, jak odnaleźć się w nowej rzeczywistości studenckiej i jak najbardziej efektywnie wykorzystać czas na studiach. Nie bójmy się podejmować nowych działań - oto moje motto ;)</p>
                </>)
            }
        ];

        this.state = {
            faces: faces
        };
    }

    render () {
        return (
            <Layout>
                <section className="relative pt-[4.8125rem] md:pt-[6.8125rem]">
                    <StaticImage src="../images/bg.jpg" alt="" className="!absolute !inset-0 !z-[-1]"  />
                    <Container>
                        <div className="">
                            <h1 className="text-white text-3xl font-semibold py-8 md:text-6xl md:py-12 lg:text-8xl lg:py-16">ChangeShapers</h1>
                        </div>
                    </Container>
                </section>

                <section className="bg-light-gray">
                    <Container>
                        <ul className="py-12 space-y-8 md:py-16 md:space-y-2">
                            {this.state.faces.map(face => (
                                <li key={face.id} id={face.id} className="bg-white p-6 md:grid md:grid-cols-[auto_11rem] md:gap-4 md:p-12 lg:grid-cols-[auto_16rem]">
                                    <div className="w-44 mx-auto mb-6 md:mx-0 md:mb-0 md:order-2 md:translate-x-12 lg:w-64">
                                        {face.img}
                                    </div>

                                    <div className="md:order-1">
                                        <h3 className="text-2xl font-semibold text-center mb-4 md:text-left md:text-4xl md:mb-6">{face.name}</h3>

                                        <div className="text-sm space-y-2 md:text-lg">
                                            {face.desc}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default ChangeShapersPage;